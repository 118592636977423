/* ------------------------------------------------------------------------------
 *
 *  # Responsive tables
 *
 *  Specific JS code additions for table_responsive.html page
 *
 *  Version: 1.0
 *  Latest update: Aug 1, 2015
 *
 * ---------------------------------------------------------------------------- */

$(function() {

	// Initialize responsive functionality
	$('.table-togglable').footable();

});