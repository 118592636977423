/* ------------------------------------------------------------------------------
 *
 *  # Steps wizard
 *
 *  Specific JS code additions for wizard_steps.html page
 *
 *  Version: 1.1
 *  Latest update: Dec 25, 2015
 *
 * ---------------------------------------------------------------------------- */

$(function() {


    // Wizard examples
    // ------------------------------

    // Basic wizard setup
    // $(".steps-basic").steps({
    //     headerTag: "h6",
    //     bodyTag: "fieldset",
    //     transitionEffect: "fade",
    //     titleTemplate: '<span class="number">#index#</span> #title#',
    //     labels: {
    //         finish: 'Submit'
    //     },
    //     onFinished: function (event, currentIndex) {
    //         alert("Form submitted.");
    //     }
    // });


    // Async content loading
    // $(".steps-async").steps({
    //     headerTag: "h6",
    //     bodyTag: "fieldset",
    //     transitionEffect: "fade",
    //     titleTemplate: '<span class="number">#index#</span> #title#',
    //     labels: {
    //         finish: 'Submit'
    //     },
    //     onContentLoaded: function (event, currentIndex) {
    //         $(this).find('select.select').select2();

    //         $(this).find('select.select-simple').select2({
    //             minimumResultsForSearch: Infinity
    //         });

    //         $(this).find('.styled').uniform({
    //             radioClass: 'choice'
    //         });

    //         $(this).find('.file-styled').uniform({
    //             fileButtonClass: 'action btn bg-warning'
    //         });
    //     },
    //     onFinished: function (event, currentIndex) {
    //         alert("Form submitted.");
    //     }
    // });


    // Saving wizard state
    // $(".steps-state-saving").steps({
    //     headerTag: "h6",
    //     bodyTag: "fieldset",
    //     saveState: false,
    //     titleTemplate: '<span class="number">#index#</span> #title#',
    //     autoFocus: true,
    //     onFinished: function(event, currentIndex) {
    //         alert("Form submitted.");
    //     }
    // });


    // Specify custom starting step
    // $(".steps-starting-step").steps({
    //     headerTag: "h6",
    //     bodyTag: "fieldset",
    //     startIndex: 2,
    //     titleTemplate: '<span class="number">#index#</span> #title#',
    //     autoFocus: true,
    //     onFinished: function (event, currentIndex) {
    //         alert("Form submitted.");
    //     }
    // });


    //
    // Wizard with validation
    //

    // Show form
    // var form = $(".steps-validation").show();


    // Initialize wizard
    // $(".steps-validation").steps({
    //     headerTag: "h6",
    //     bodyTag: "fieldset",
    //     transitionEffect: "fade",
    //     titleTemplate: '<span class="number">#index#</span> #title#',
    //     autoFocus: true,
    //     onStepChanging: function (event, currentIndex, newIndex) {

    //         // Allways allow previous action even if the current form is not valid!
    //         if (currentIndex > newIndex) {
    //             return true;
    //         }

    //         // Forbid next action on "Warning" step if the user is to young
    //         if (newIndex === 3 && Number($("#age-2").val()) < 18) {
    //             return false;
    //         }

    //         // Needed in some cases if the user went back (clean up)
    //         if (currentIndex < newIndex) {

    //             // To remove error styles
    //             form.find(".body:eq(" + newIndex + ") label.error").remove();
    //             form.find(".body:eq(" + newIndex + ") .error").removeClass("error");
    //         }

    //         form.validate().settings.ignore = ":disabled,:hidden";
    //         return form.valid();
    //     },

    //     onStepChanged: function (event, currentIndex, priorIndex) {

    //         // Used to skip the "Warning" step if the user is old enough.
    //         if (currentIndex === 2 && Number($("#age-2").val()) >= 18) {
    //             form.steps("next");
    //         }

    //         // Used to skip the "Warning" step if the user is old enough and wants to the previous step.
    //         if (currentIndex === 2 && priorIndex === 3) {
    //             form.steps("previous");
    //         }
    //     },

    //     onFinishing: function (event, currentIndex) {
    //         form.validate().settings.ignore = ":disabled";
    //         return form.valid();
    //     },

    //     onFinished: function (event, currentIndex) {
    //         alert("Submitted!");
    //     }
    // });


    // Initialize validation
    // $(".steps-validation").validate({
    //     ignore: 'input[type=hidden], .select2-search__field', // ignore hidden fields
    //     errorClass: 'validation-error-label',
    //     successClass: 'validation-valid-label',
    //     highlight: function(element, errorClass) {
    //         $(element).removeClass(errorClass);
    //     },
    //     unhighlight: function(element, errorClass) {
    //         $(element).removeClass(errorClass);
    //     },

    //     // Different components require proper error label placement
    //     errorPlacement: function(error, element) {

    //         // Styled checkboxes, radios, bootstrap switch
    //         if (element.parents('div').hasClass("checker") || element.parents('div').hasClass("choice") || element.parent().hasClass('bootstrap-switch-container') ) {
    //             if(element.parents('label').hasClass('checkbox-inline') || element.parents('label').hasClass('radio-inline')) {
    //                 error.appendTo( element.parent().parent().parent().parent() );
    //             }
    //              else {
    //                 error.appendTo( element.parent().parent().parent().parent().parent() );
    //             }
    //         }

    //         // Unstyled checkboxes, radios
    //         else if (element.parents('div').hasClass('checkbox') || element.parents('div').hasClass('radio')) {
    //             error.appendTo( element.parent().parent().parent() );
    //         }

    //         // Input with icons and Select2
    //         else if (element.parents('div').hasClass('has-feedback') || element.hasClass('select2-hidden-accessible')) {
    //             error.appendTo( element.parent() );
    //         }

    //         // Inline checkboxes, radios
    //         else if (element.parents('label').hasClass('checkbox-inline') || element.parents('label').hasClass('radio-inline')) {
    //             error.appendTo( element.parent().parent() );
    //         }

    //         // Input group, styled file input
    //         else if (element.parent().hasClass('uploader') || element.parents().hasClass('input-group')) {
    //             error.appendTo( element.parent().parent() );
    //         }

    //         else {
    //             error.insertAfter(element);
    //         }
    //     },
    //     rules: {
    //         email: {
    //             email: true
    //         }
    //     }
    // });



    // Initialize plugins
    // ------------------------------

    // Select2 selects
    // $('.select').select2();


    // // Simple select without search
    // $('.select-simple').select2({
    //     minimumResultsForSearch: Infinity
    // });


    // // Styled checkboxes and radios
    // $('.styled').uniform({
    //     radioClass: 'choice'
    // });


    // // Styled file input
    // $('.file-styled').uniform({
    //     fileButtonClass: 'action btn bg-blue'
    // });

});